<template>
    <f7-sheet
        :opened="shown"
        class="popup_banner_sheet"
        swipe-to-close
        @sheet:closed="closeModal()"
    >
        <div :class="`popup_banner_sheet__close ${popup_banner.content_type === 'image_bg' ? 'popup_banner_sheet__close__bg' : ''}`" @click="closeModal()"></div>
        <div :class="`popup_banner_sheet__swipe-line ${popup_banner.content_type === 'image_bg' ? 'popup_banner_sheet__swipe-line__bg' : ''}`"></div>

        <template v-if="popup_banner.id">
            <template v-if="popup_banner.content_type === 'image'">
                <div class="popup_banner_sheet__content">
                    <div class="popup_banner_sheet__header">
                        <div v-if="popup_banner.main_text" class="popup_banner_sheet__title">{{ popup_banner.main_text }}</div>
                        <div v-if="popup_banner.additional_text" class="popup_banner_sheet__text">{{ popup_banner.additional_text }}</div>
                    </div>

                    <div class="popup_banner_sheet__image">
                        <img :src="imageSrc(popup_banner.picture)" />
                    </div>
                    
                    <div class="popup_banner_sheet__buttons">
                        <button 
                            v-if="popup_banner.button_name"
                            class="popup_banner_sheet__button"
                            @click="onClick(popup_banner)"
                        >{{ popup_banner.button_name }}</button>
                    </div>
                </div>
            </template>

            <template v-if="popup_banner.content_type === 'image_bg'">
                <div class="popup_banner_sheet__content popup_banner_sheet__content__bg">
                    <div v-if="popup_banner.main_text || popup_banner.additional_text" class="popup_banner_sheet__header popup_banner_sheet__header__bg">
                        <div v-if="popup_banner.main_text" class="popup_banner_sheet__title popup_banner_sheet__title__bg">{{ popup_banner.main_text }}</div>
                        <div v-if="popup_banner.additional_text" class="popup_banner_sheet__text popup_banner_sheet__text__bg">{{ popup_banner.additional_text }}</div>
                    </div>
                    
                    <div class="popup_banner_sheet__image popup_banner_sheet__image__bg" :style="`background-image: url(${imageSrc(popup_banner.picture)})`"></div>
                    
                    <div class="popup_banner_sheet__buttons popup_banner_sheet__buttons__bg">
                        <button 
                            v-if="popup_banner.button_name"
                            class="popup_banner_sheet__button"
                            @click="onClick(popup_banner)"
                        >{{ popup_banner.button_name }}</button>
                    </div>
                </div>
            </template>

            <template v-if="['video_mp4', 'video_webm'].includes(popup_banner.content_type)">
                <div class="popup_banner_sheet__content">
                    <div class="popup_banner_sheet__header">
                        <div v-if="popup_banner.main_text" class="popup_banner_sheet__title">{{ popup_banner.main_text }}</div>
                        <div v-if="popup_banner.additional_text" class="popup_banner_sheet__text">{{ popup_banner.additional_text }}</div>
                    </div>
                    
                    <div class="popup_banner_sheet__image">
                        <video 
                            playsinline 
                            autoplay 
                            muted 
                            loop 
                            width="100%"
                            :poster="imageSrc(popup_banner.picture)">
                            <source v-if="popup_banner.content_type === 'video_mp4'" :src="imageSrc(popup_banner.video)" type="video/mp4">
                            <source v-if="popup_banner.content_type === 'video_webm'" :src="imageSrc(popup_banner.video)" type="video/webm">
                        </video>
                    </div>

                    <div class="popup_banner_sheet__buttons">
                        <button 
                            v-if="popup_banner.button_name"
                            class="popup_banner_sheet__button"
                            @click="onClick(popup_banner)"
                        >{{ popup_banner.button_name }}</button>
                    </div>
                </div>
            </template>
        </template>

    </f7-sheet>
</template>

<script>
    import { f7 } from 'framework7-vue';
    import { mapState } from 'vuex';
    import store from '@/store';
    import indexedDB from '@/plugins/indexedDB';
    
    import { goToExternalLink, sendAppMetricaEvent } from '@/js/utils/service-helpers';

    export default {
        name: 'PopupBanner',

        components: {
        },

        data: () => ({
            shown: false,
            popup_banner: {},
            checkShow: false,
            checkActiveStories: false,
        }),

        computed: {
            ...mapState('banners', {
                banners: state => state.popup_banners_list
            }),
            ...mapState('orders', ['completedCount']),
            ...mapState('auth', {
                user: state => state.user
            }),
        },

        async created() {
            this.$root.$on('storiesShow', () => {
                this.checkActiveStories = true;
            });
            this.$root.$on('popupBannerSheetShow', async () => {
                // if (this.checkActiveStories) {
                //     return;
                // }
                // const checkStories = await this.hasActiveStories();
                // if (!checkStories) {
                //     return;
                // }

                // if (store.state.auth.user && store.state.auth.user.phone) {
                //     let orderForRepeat = await indexedDB.get('mircity-orderForRepeat');
                //     if(orderForRepeat) {
                //         await indexedDB.remove('mircity-orderForRepeat');
                //     }
                // }

                await store.dispatch('banners/getPopupList');
                for (const banner of this.banners) {
                    if (this.shown === false) {
                        this.popup_banner = banner;
                        sendAppMetricaEvent('POPUP_BANNER_OPENED', {
                            Action: 'view',
                            Page: 'main',
                            Banner: this.popup_banner?.code || this.popup_banner?.id
                        });
                        this.shown = true;
                    }
                }
                
                // await store.dispatch('banners/getPopupList');
                // let popupBanner = await indexedDB.get('mircity-popupBannerForMainPage') || [];
                // for (const banner of this.banners) {
                //     const checkBanner = popupBanner.find(a => a === banner.id);
                //     if (!checkBanner && this.shown === false) {
                //         this.popup_banner = banner;
                //         popupBanner.push(this.popup_banner.id)
                //         await indexedDB.set('mircity-popupBannerForMainPage', popupBanner );
                //         sendAppMetricaEvent('POPUP_BANNER_OPENED', {
                //             Action: 'view',
                //             Page: 'main',
                //             Banner: this.popup_banner?.code || this.popup_banner?.id
                //         });
                //         this.shown = true;
                //         this.checkShow = true;
                //     }
                // }
            });
        },
        methods: {
            imageSrc(picture) {
                return picture ? `${process.env.VUE_APP_IMAGES_URL}/${ picture }` : '';
            },

            async onClick(banner) {
                if (banner?.type) {
                    sendAppMetricaEvent('POPUP_BANNER_CLICK', {
                        Action: 'click',
                        Page: 'main',
                        Banner: this.popup_banner?.code || this.popup_banner?.id
                    });
                }


                switch(banner.type) {
                case 'link':
                    await f7.views[0].router.navigate(banner.link);
                    this.shown = false;
                    break
                case 'external_link':
                    goToExternalLink(banner.link);
                    this.shown = false;
                    break;
                case 'list':
                    await f7.views[0].router.navigate({
                        name: `${banner.route_name}-banner`,
                        params: {
                            id: banner.id
                        }
                    });
                    this.shown = false;
                    break;
                case 'products_list':
                    await f7.views[0].router.navigate({
                        name: banner.route_name + '-products-banner',
                        params: {
                            id: banner.id
                        }
                    });
                    this.shown = false;
                    break;
                default:
                    this.shown = false;
                    return false;
                }
            },
            async hasActiveStories() {
                let activeStories = await indexedDB.get('mircity-activeAllStories') || [];
                const storiesIndexes = await indexedDB.get('mircity-storiesIndexes') || {};
                const allStories = Object.values(storiesIndexes)
                if (allStories.length === 0) {
                    return true
                }
                activeStories.sort();
                allStories.sort();
                for(let i = 0; i < allStories.length; i++){
                    if (activeStories.indexOf(allStories[i]) == -1) {
                        return false;
                    }
                }
                return true;
            },
            closeModal() {
                this.shown = false;
                window.dispatchEvent(new Event('resize'));
            }
        },
    };
</script>

<style lang="scss">
.popup_banner_sheet {
    border-radius: 24px;
    --f7-sheet-bg-color: #fff;
    height: calc(100vh - constant(safe-area-inset-top));
    height: calc(100vh - env(safe-area-inset-top));
    padding-bottom: 82px;
    // padding-bottom: constant(safe-area-inset-bottom);
    // padding-bottom: env(safe-area-inset-bottom);

    &__content {
        position: relative;
        overflow: hidden;
        height: calc(100vh - constant(safe-area-inset-top) - 82px);
        height: calc(100vh - env(safe-area-inset-top) - 82px);
        max-height: calc(100vw * 1.81);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    & > .sheet-modal-inner {
        padding: 0 !important;
    }

    &__close {
        position: absolute;
        top: 7px;
        right: 10px;
        width: 32px;
        height: 32px;
        background-image: url('../../static/popup_close.svg');
        background-repeat: no-repeat;
        background-position: center;
        z-index: 100;

        &__bg {
            background-image: url('../../static/popup_close_bg.svg');
        }
    }

    &__swipe-line {
        position: absolute;
        width: 40px;
        height: 4px;
        top: 16px;
        left: 50%;
        margin-left: -20px;
        border-radius: 23px;
        background: #A9B2BF;
        z-index: 100;

        &__bg {
            background: #CDD3D9;
        }
    }

    &__image {
        width: 100%;
        overflow: hidden;
        border-radius: 24px;
        max-height: calc(100vw * 1.51);

        &__bg {
            height: 100%;
            max-height: 100%;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
        }
    }
    
    &__header {
        padding: 46px 20px 0 20px;

        &__bg {
            position: absolute;
            top: 0;
            width: 100%;
        }
    }

    &__title {
        font-weight: 600;
        font-size: 18px;
        line-height: 19px;
        text-align: center;
        color: #000000;
        padding-bottom: 10px;

        &__bg {
            color: #CDD3D9;
        }
    }
    
    &__text {
        font-weight: 400;
        font-size: 14px;
        line-height: 120%;
        text-align: center;
        color: #90949A;
        padding-bottom: 10px;

        &__bg {
            color: #CDD3D9;
        }
    }

    &__buttons {
        padding: 20px 40px 0 40px;

        &__bg {
            position: absolute;
            bottom: 0;
            width: 100%;
            padding: 30px 40px;
        }
    }

    &__button {
        width: 100%;
        height: 44px;
        border-radius: 36px;
        font-family: Roboto;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        text-align: center;
        background: #FD7F85;
    }
}
</style>
