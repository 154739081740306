<template>
    <f7-page 
        :id="name" 
        :class="`${ backButtonVisible ? '' : 'no-swipeback' } app-page-main app-page-main__${ name } ${ isCordova ? '' : 'custom-scrollbar' }`" 
        :name="name"
        no-swipeback
        v-bind="$attrs" 
        :style="cssVars"
        v-on="$listeners"
    >
        <!-- <div :class="[!entityServiceShowSticky ? 'main-toolbar-top' : 'main-toolbar-top_for_sticky' ]"> -->
        <div class="main-toolbar-top">
            <div :class="`main-toolbar-top__wrapper__${ name } main-toolbar-top__wrapper`">
                <div :class="`main-toolbar-top__content__${ name } main-toolbar-top__content`">
                    <div 
                        :class="[visibleButtonClearCart && !searchButtonVisible ? 'w-2/6 h-full' : 'w-1/6 h-full']">
                        <button
                            v-show="backButtonVisible"
                            class="w-full h-full"
                            style="padding-left: 20px;"
                            @click="back">
                            <img :src="backButtonSrc" class="self-start h-4 rounded-none" />
                        </button>
                    </div>
                    <template v-if="maskVisible || !isStickyTitle">
                        <div 
                            :class="[visibleButtonClearCart && !searchButtonVisible ? 'w-2/6 h-full' : 'w-4/6 h-full']">
                            <button class="w-full h-full flex justify-center align-items-center" @click="$f7router.navigate({ name: 'home-force' })">
                                <span
                                    v-if="entityServiceName"  
                                    class="main-toolbar-service-top__logo navbar_service_name">
                                    {{ entityServiceName }}
                                </span>
                                <span 
                                    v-else-if="serviceName"
                                    class="main-toolbar-top__serv_logo navbar_service_name">
                                    {{ serviceName }}
                                </span>
                                <img
                                    v-else
                                    :src="logoSrc"
                                    class="main-toolbar-top__logo" />
                            </button>
                            <slot name="nav-right"></slot>
                        </div>
                        <div 
                            class="flex justify-end app-page-clear-button-width"
                            :class="[visibleButtonClearCart && !searchButtonVisible ? 'w-2/6 h-full' : 'w-1/6 h-full']">
                            <button
                                v-if="visibleButtonClearCart && !searchButtonVisible"
                                v-show="visibleButtonClearCart"
                                class="w-full h-full flex justify-end align-items-center app-page-clear-cart"
                                style="padding-right: 20px; width: 100%"
                                @click="$emit('click-clear-cart')">
                                Удалить все &nbsp;
                                <img :src="clearCartButtonSrc" class="h-4" />
                            </button>
                            <!-- <button
                                v-else
                                v-show="searchButtonVisible"
                                class="w-full h-full flex justify-end align-items-center"
                                style="padding-right: 20px;"
                                @click="$emit('click-search')">
                                <img :src="searchButtonSrc" class="h-4" />
                            </button> -->
                        </div>
                    </template>
                    <template v-else>
                        <div v-if="!maskVisible" class="w-4/5 h-full flex items-center">
                            <div class="main-toolbar-top__entity-title">{{ entityNameShortener }}</div>
                        </div>
                    </template>
                </div>
            </div>
        </div>

        <f7-toolbar 
            v-if="bottomNavVisible && !storiesOpen"
            class="app-nav-bottom"
            :no-hairline="true"
            :inner="false"
            bottom>
            <AppNavBottomContent
                :active-menu="activeMenu" />
        </f7-toolbar>

        <div class="page-content__container">
            <slot></slot>
            <div v-if="bottomNavVisible" style="height: 70px"></div>
        </div>
    </f7-page>
</template>

<script>
    import { Device } from 'framework7';
    import { mapGetters, mapState } from 'vuex';
    import store from '@/store';
    import AppNavBottomContent from '@/components/common/AppNavBottomContent';
    import { checkVersion, updatePushToken } from '@/js/utils/service-helpers';
    import { CssVarsEnum } from '@/js/utils/vars';
    import { bus } from '@/js/utils/bus';
    import Stories from '@/components/dialogs/Stories';

    export default {
        components: {
            AppNavBottomContent,
            Stories
        },
        props: {
            name: {
                type: String,
                required: true,
            },
            backButtonLink: {
                type: String,
                default: '',
            },
            topNavVisible: {
                type: Boolean,
                default: true,
            },
            bottomNavVisible: {
                type: Boolean,
                default: true,
            },
            backButtonVisible: {
                type: Boolean,
                default: false,
            },
            searchButtonVisible: {
                type: Boolean,
                default: false,
            },
            clearHistory: {
                type: Boolean,
                default: false,
            },
            maskColor: {
                type: String,
                default: '',
            },
            service: {
                type: String,
                default: ''
            },
            entityName: {
                type: String,
                default: ''
            },
            scrollLimit: {
                type: Number,
                default: 130
            },
            isStickyTitle: {
                type: Boolean,
                default: false
            },
            activeMenu: {
                type: String,
                default: 'services'
            },
            serviceName: {
                type: String,
                default: ''
            },
            entityServiceName: {
                type: String,
                default: ''
            },
            visibleButtonClearCart: {
                type: Boolean,
                default: false,
            },
            entityServiceShowSticky: {
                type: Boolean,
                default: false,
            },
        },

        data: () => ({
            maskVisible: true,
            visible: false,
            storiesOpen: false,
        }),

        computed: {
            ...mapGetters('cart', ['count', 'isEmpty']),
            ...mapState('style', ['colors', 'backgrounds']),
            ...mapState('settings', {
                settings: state => state.settings.home
            }),
            logoSrc() {
                return 'static/logo.svg';
            },
            isCordova() {
                return Device.cordova ? true : false
            },
            backButtonSrc() {
                return 'static/arrow-left.svg';
            },
            searchButtonSrc() {
                return 'static/search-header.svg';
            },
            clearCartButtonSrc() {
                return 'static/clear-cart.svg';
            },
            cssVars() {
                const code = this?.$f7router?.currentRoute?.params?.entityTypeCode;
                if (code && this.backgrounds) {
                    return {
                        [CssVarsEnum.ENTITY_BG_COLOR]: this.backgrounds[code] || '',
                        [CssVarsEnum.ENTITY_COLOR]: this.backgrounds[code] || ''
                    }
                }
                return {};
            },
            entityNameShortener() {
                return this.entityName.length > 30 ? `${this.entityName.substring(0, 30)}...` : this.entityName;
            }
        },

        async created() {
            // Check app version
            await checkVersion({ key: Device.cordova ? process.env.VUE_APP_VERSION_MOBILE_KEY : process.env.VUE_APP_VERSION_WEB_KEY, this_: this });
            // Update push token
            // updatePushToken();

            const code = this?.$f7router?.currentRoute?.params?.entityTypeCode;
            if (code && !this.backgrounds[code]) {
                await this.$store.dispatch('style/fetch');
            }
        },

        beforeUnmount() {
            this.$$(`#${this.name} > .page-content`).off('scroll', this.scrollHandler);
            this.$$(`#${this.name} > .page-content`).off('touchstart', this.touchstartHandler);
        },

        async mounted() {
            await store.dispatch('settings/fetch', { page: 'home' });
            if(this.settings.pictures?.active) {
                const style = document.documentElement.style;
                style.setProperty('--header_320_left', `url(${this.imageSrc(this.settings.pictures.header_320_left)})`);
                style.setProperty('--header_320_right', `url(${this.imageSrc(this.settings.pictures.header_320_right)})`);
                style.setProperty('--header_375_left', `url(${this.imageSrc(this.settings.pictures.header_375_left)})`);
                style.setProperty('--header_375_right', `url(${this.imageSrc(this.settings.pictures.header_375_right)})`);
            }

            const this_ = this;
            this.$root.$on('shownAllStories', () => {
                this_.storiesOpen = true;
            });
            this.$root.$on('hiddenAllStories', () => {
                this_.storiesOpen = false;
            });
            const $scroller = this_.$$(`#${this.name} > .page-content`);
            $scroller.on('scroll', this.scrollHandler);
            $scroller.on('touchstart', this.touchstartHandler);
            // $scroller.each(function (i, el) {
            //     this_.$$(el).on('touchstart', function () {
            //         if ($scroller.scrollTop() === 0) {
            //             $scroller.scrollTop(1);
            //         }
            //     });
            // });

            // Auto update order statuses
            window.clearInterval(this.$root.orderTimerId);
            // if(this.$f7route.name === 'orders-list' || this.$f7route.name === 'profile') {
            //     this.$root.orderTimerId = window.setInterval(async () => {
            //         await store.dispatch('orders/fetch', {});
            //     }, 10000);
            // }
            if(this.$f7route.name === 'orders-detail') {
                this.$root.orderTimerId = window.setInterval(async () => {
                    const orderId = this.$f7route.params.orderId;
                    if (!orderId) return;
                    await store.dispatch('orders/get', {
                        orderId
                    });
                }, 10000);
            }
        },

        methods: {
            imageSrc(picture) {
                return picture ? `${process.env.VUE_APP_IMAGES_URL}/${ picture }` : '';
            },
            async checkIfSheetOpen() {
                await this.timeout(2000);
                const sheet = this?.$f7?.sheet?.get();
                if (sheet?.opened) {
                    this.canOpen = false;
                    this.checkIfSheetOpen();
                    return;
                }

                this.canOpen = true;
                return;
            },
            async back() {
                store.commit('surveys/SET_LOADED');
                await store.dispatch('settings/fetch', { page: 'home' });

                if(this.clearHistory) {
                    this.$f7router.navigate({
                        name: 'home-force'
                    });
                } else {
                    if(this.backButtonLink !== '') {
                        this.$f7router.navigate(this.backButtonLink);
                    } else if(this.$f7router.history.length > 2) {
                        this.$f7router.back();
                    } else {
                        this.$f7router.navigate({
                            name: 'home-force'
                        });
                    }
                }
            },
            scrollHandler(e) {
                bus.$emit('pageScroll', e.target.scrollTop);

                if(e.target.scrollTop > this.scrollLimit) {
                    this.$emit('toggleSticky', true);
                } else {
                    this.$emit('toggleSticky', false);
                }
            },
            touchstartHandler(e) {
                if (this.$$(`#${this.name} > .page-content`).scrollTop() === 0) {
                    this.$$(`#${this.name} > .page-content`).scrollTop(1);
                }
            },
            storyOpen() {
                this.storiesOpen = true;
            },
            storyClose() {
                this.storiesOpen = false;
            },
        },
    };
</script>

<style lang="scss">
.app-page-clear-button-width {
    .w-1\/6 {
        width: 100.666667% !important;
    }
}
.navbar_services_name {
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.navbar_service_name {
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.app-page-clear-cart {
    color: #AFAFAF;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
}
.app-page-main {
    min-width: 100%;
    width: 100%;
}
.app-page-main .page-content {
    min-width: 100%;
    width: 100%;
    padding-top: 0;
}

.app-page-main.custom-scrollbar .page-content {
    &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }
    &::-webkit-scrollbar-track {
        border-radius: 5px;
        background-color: #f6f6f6;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }
}

.main-toolbar-top_for_sticky {
    position: fixed;
    top: constant(safe-area-inset-top);
    top: env(safe-area-inset-top);
    overflow: hidden;
    width: 100%;
    max-width: 600px;
    height: calc(constant(safe-area-inset-top) + 70px);
    height: calc(env(safe-area-inset-top) + 70px);
    margin: 0 auto;
    box-shadow: 0px 11px 41px rgba(0, 0, 0, 0.06);
    // border-radius: 0px 0px 20px 20px;
    background-color: #fff;
    z-index: 110;

    &__content {
        display: flex;
        align-items: center;
        width: 100%;
        max-width: 600px;
        height: 70px;
        margin-top: constant(safe-area-inset-top);
        margin-top: env(safe-area-inset-top);
    }

    &__back {
        width: 40px;
        height: 32px;
        position: absolute;
        top: 19px;
        left: 28px;
        background-image: url('../../static/arrow-left.svg');
        background-position: left center;
        background-repeat: no-repeat;
    }

    &__serv_logo {
        width: 100%;
        height: 21px;
    }

    &__logo {
        width: 120px;
        height: 21px;
    }
}

.main-toolbar-top {
    position: fixed;
    top: 0;
    overflow: hidden;
    width: 100%;
    max-width: 600px;
    height: calc(constant(safe-area-inset-top) + 70px);
    height: calc(env(safe-area-inset-top) + 70px);
    padding-top: constant(safe-area-inset-top);
    padding-top: env(safe-area-inset-top);
    margin: 0 auto;
    box-shadow: 0px 11px 41px rgba(0, 0, 0, 0.06);
    border-radius: 0px 0px 20px 20px;
    background: #fff;
    z-index: 110;

    &__wrapper {
        max-width: 600px;
        height: 70px;
        background-color: #fff;
        background-image: var(--header_320_left);
        background-position: left center;
        background-repeat: no-repeat;
        background-size: contain;

        @media screen and (min-width: 375px) {
            background-image: var(--header_375_left);
        }
    }

    &__content {
        display: flex;
        align-items: center;
        width: 100%;
        max-width: 600px;
        height: 70px;
        background-image: var(--header_320_right);
        background-position: right center;
        background-repeat: no-repeat;
        background-size: contain;

        @media screen and (min-width: 375px) {
            background-image: var(--header_375_right);
        }
    }

    &__back {
        width: 40px;
        height: 32px;
        position: absolute;
        top: 19px;
        left: 28px;
        background-image: url('../../static/arrow-left.svg');
        background-position: left center;
        background-repeat: no-repeat;
    }

    &__serv_logo {
        width: 100%;
        height: 21px;
    }

    &__logo {
        width: 120px;
        height: 50px;
    }
}

.main-toolbar-service-top {
    position: fixed;
    top: 0;
    overflow: hidden;
    width: 100%;
    max-width: 600px;
    height: calc(constant(safe-area-inset-top) + 70px);
    height: calc(env(safe-area-inset-top) + 70px);
    margin: 0 auto;
    box-shadow: 0px 11px 41px rgba(0, 0, 0, 0.06);
    border-radius: 0px 0px 20px 20px;
    background-color: #fff;
    z-index: 110;

    &__content {
        display: flex;
        align-items: center;
        width: 100%;
        max-width: 600px;
        height: 70px;
        margin-top: constant(safe-area-inset-top);
        margin-top: env(safe-area-inset-top);
    }

    &__back {
        width: 40px;
        height: 32px;
        position: absolute;
        top: 19px;
        left: 28px;
        background-image: url('../../static/arrow-left.svg');
        background-position: left center;
        background-repeat: no-repeat;
    }

    &__logo {
        width: 90%;
        height: 21px;
    }
}

.page-content__container {
    position: relative;
    z-index: 50px;
    height: 100%;
    padding-top: calc(constant(safe-area-inset-top) + 85px);
    padding-top: calc(env(safe-area-inset-top) + 85px);
}

.main-toolbar-top__entity-title {
	font-size: 18px;
	font-weight: 500;
	height: 22px;
	overflow: hidden;
}
</style>