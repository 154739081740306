import axios from '../../plugins/axios-silent';
import indexedDB from '../../plugins/indexedDB';

export default {
  namespaced: true,

  state: {
    onboarding: null,
    onboardings: [],
    stories: [],
  },

  mutations: {
    SET_ONBOARDING(state, payload) {
      state.onboarding = payload;
    },
    SET_ONBOARDINGS(state, payload) {
      state.onboardings = payload;
    },
    SET_STORIES(state, payload) {
      state.stories = payload;
    },
    CLEAR_ONBOARDING(state) {
      state.onboarding = null;
    },
    CLEAR_ONBOARDINGS(state) {
      state.onboardings = [];
    },
    CLEAR_STORIES(state) {
      state.stories = [];
    },
  },

  actions: {
    async fetchOnboardings({ commit }) {
      try {
        const { data } = await axios.get('/front/onboarding');
        commit('SET_ONBOARDINGS', data);

        let localOnboardings = await indexedDB.get('mircity-onboardings');
        if (!localOnboardings) {
          localOnboardings = [];
        }

        for (const remoteItem of data) {
          const existingIndex = localOnboardings.findIndex(
            loc => loc.id === remoteItem.id
          );

          if (existingIndex === -1) {
            localOnboardings.push({
              ...remoteItem,
              showCount: 0,
            });
          } else {
            const existingItem = localOnboardings[existingIndex];
            localOnboardings[existingIndex] = {
              ...existingItem,
              ...remoteItem,
            };
          }
        }

        await indexedDB.set('mircity-onboardings', localOnboardings);
      } catch (error) {
        console.error('Ошибка при получении онбордингов:', error);
      }
    },

    async fetchOnboarding({ commit }, onboardingId) {
      try {
        const { data } = await axios.get(`/front/onboarding/${onboardingId}`);
        commit('SET_ONBOARDING', data);
      } catch (error) {
        console.error('Ошибка при получении онбординга:', error);
      }
    },

    async fetchStories({ commit }, onboardingId) {
      try {
        const { data } = await axios.get(`/front/onboarding/${onboardingId}/stories`);
        commit('SET_STORIES', data);
      } catch (error) {
        console.error('Ошибка при получении сторисов:', error);
      }
    },

    clearOnboarding({ commit }) {
      commit('CLEAR_ONBOARDING');
    },

    clearOnboardings({ commit }) {
      commit('CLEAR_ONBOARDINGS');
    },

    clearStories({ commit }) {
      commit('CLEAR_STORIES');
    },
  },
};
